import { useEffect } from 'react';
import get from 'lodash/get';
import { useLocation, useNavigate } from 'react-router-dom';
import { RecoveryFormDataType } from 'interfaces/kratos';
import ForgetPasswordConfirmationComponent from './ForgetPasswordConfirmation';

const ForgetPasswordConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formData: RecoveryFormDataType = get(location, ['state', 'formData']);
  const formAction: string = get(location, ['state', 'action']);
  useEffect(() => {
    if (!formData) {
      navigate('/auth/login', { replace: true });
    }
  }, [formData, navigate]);

  return <ForgetPasswordConfirmationComponent formData={formData} formAction={formAction} />;
};

export default ForgetPasswordConfirmation;
