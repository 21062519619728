import { Typography } from '@carecru/component-library';
import { Translated } from 'i18n';

const LoginHeaders = () => (
  <Typography className="login-form__title">
    <Translated id="view_login_title" />
  </Typography>
);

export default LoginHeaders;
