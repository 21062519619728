import { MessageDescriptor, useIntl } from 'react-intl';

type UseFormatMessageValues = Record<
  string,
  string | number | boolean | null | undefined
>;

const useFormatMessage = (
  descriptor: MessageDescriptor,
  values?: UseFormatMessageValues,
): string => {
  const intl = useIntl();
  return intl.formatMessage(descriptor, values);
};

export default useFormatMessage;
