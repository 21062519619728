import { UiNode, UiNodeInputAttributes } from '@ory/kratos-client';
import { useFormatMessage } from 'i18n';
import { validateEmailField, validateForm } from 'helpers';
import { useState } from 'react';
import Field from 'components/Field';
import { extractFieldByName } from 'helpers/flow';

type TranslationMapType = {
  email: string;
};

const useTranslationMap = (): TranslationMapType => ({
  email: useFormatMessage({ id: 'form_label_password_identifier' }),
});

interface InitialStateType {
  email: string;
  invalidEmail: boolean;
}

const initialState: InitialStateType = {
  email: '',
  invalidEmail: false,
};

const RecoveryFields = ({
  fields,
  onChange,
}: {
  fields: UiNode[];
  onChange: (isValid: boolean) => void;
}) => {
  const fieldTranslationMap = useTranslationMap();
  const [state, setState] = useState(initialState);
  const emailUiNode = extractFieldByName(fields, 'email');

  const validateEmail = (value: string): void => {
    const { errorMessages, invalidEmail } = validateEmailField(value);
    emailUiNode.messages = errorMessages;
    setState({
      ...state,
      invalidEmail,
    });
  };

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    if (name === 'email') validateEmail(value);
    const isValid = validateForm(fields);
    onChange(!!isValid);
  };

  return (
    <div className="recovery-form__fields">
      {emailUiNode && (
        <Field
          field={emailUiNode}
          label={fieldTranslationMap.email}
          placeholder={fieldTranslationMap.email}
          className="mb-4"
          onChange={handleChange}
          isInvalid={state.invalidEmail}
        />
      )}
      

      {fields
        ?.filter((field) => field !== emailUiNode)
        .map((field) => {
          const { name } = field.attributes as UiNodeInputAttributes;

          return (
            <Field
              field={field}
              label={fieldTranslationMap[name as keyof TranslationMapType]}
              placeholder={fieldTranslationMap[name as keyof TranslationMapType]}
            />
          );
        })}
    </div>
  );
};

export default RecoveryFields;
