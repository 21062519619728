import get from 'lodash/get';
import { UiNode, UiNodeInputAttributes, UiText } from '@ory/kratos-client';
import { KratosFlows } from 'interfaces/kratos';
import isEmpty from 'lodash/isEmpty';

export const extractFields = (flow: KratosFlows | undefined): UiNode[] => flow?.ui?.nodes || [];

export const extractAction = (flow: KratosFlows | undefined): string => flow?.ui?.action || '';

export const extractFieldErrors = (field: UiNode) =>
  get(field, 'messages', []).filter((message: UiText) => message.type === 'error');

export const extractCSRFToken = (flow: KratosFlows | undefined): UiNode =>
  flow?.ui?.nodes.find((field: UiNode) => {
    const attributes = field.attributes as UiNodeInputAttributes;
    return attributes.name === 'csrf_token';
  }) || ({} as UiNode);

export const extractMessages = (flow: KratosFlows | undefined): UiText[] =>
  get(flow, ['ui', 'messages'], []);

export const extractFieldByName = (fields: UiNode[], name: string): UiNode =>
  fields?.find((field: UiNode) => {
    const attributes = field.attributes as UiNodeInputAttributes;
    return attributes.name === name;
  }) as UiNode;

export const getTraitsUiNode = (fields: UiNode[], fieldName: string) =>
  fields.find((field: UiNode) => {
    const attributes = field.attributes as UiNodeInputAttributes;
    return attributes.name === `traits.${fieldName}` && isEmpty(attributes.value);
  }) || ({} as UiNode);
