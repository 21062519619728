import React from 'react';
import config from 'config';

const onRenderCallback: React.ProfilerOnRenderCallback = (
  _id,
  phase,
  actualDuration,
) =>
  window.console.info(
    `Phase: ${phase}, Duration: ${actualDuration.toFixed(2)}ms`,
  );

interface ProfileProps {
  children?: React.ReactNode;
  useInTest?: boolean;
}

const Profiler: React.FC<ProfileProps> = ({ children, useInTest = false }: ProfileProps) => (
  <>
    {config.env.isDev || useInTest ? (
      <React.Profiler id="application" onRender={onRenderCallback}>
        {children}
      </React.Profiler>
    ) : (
      children
    )}
  </>
);

export default Profiler;
