import { useState, useEffect } from 'react';
import { Form } from '@carecru/component-library';
import { extractFields, extractAction, extractMessages } from 'helpers/flow';
import serialize from 'form-serialize';
import { LoginFlow, LoginFormDataType } from 'interfaces/kratos';
import Messages from 'components/Messages';
import { AxiosError, AxiosResponse } from 'axios';
import { generateLoginFlow, fireLoginRequest } from './helpers';
import { LoginButtons, LoginFields, LoginHeaders } from './components';

const Login: React.FC = () => {
  const [flow, setFlow] = useState<LoginFlow>();
  const [loading, setLoading] = useState<boolean>(false);
  const fields = extractFields(flow);
  const action = extractAction(flow);
  const messages = extractMessages(flow);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [touched, setTouched] = useState<boolean>(false);

  const onSuccessfulFlowGeneration = (response: AxiosResponse): void => {
    setFlow(response.data);
    setLoading(false);
  };

  const navigateToCCP = (): void => {
    window.location.replace(`${window.location.protocol}//${window.location.host}/`);
  };

  const onFailedFlowGeneration = (error: AxiosError): void => {
    if (error?.response?.status === 400) {
      // TODO: Currently we're assuming a 400 only occurs when a valid session exists.
      navigateToCCP();
    }
  };

  const requestLoginFlow = (): void => {
    setLoading(true);
    generateLoginFlow().then(onSuccessfulFlowGeneration).catch(onFailedFlowGeneration);
  };

  const onSuccessfulLogin = (): void => {
    navigateToCCP();
  };

  const onFailedLogin = (error: AxiosError): void => {
    setFlow(error?.response?.data);
    setLoading(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!touched || disableSubmit) return;

    setLoading(true);
    const form = e.currentTarget;
    const formData = serialize(form, { hash: true });

    fireLoginRequest(form.action, formData as unknown as LoginFormDataType)
      .then(onSuccessfulLogin)
      .catch(onFailedLogin);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(requestLoginFlow, []);

  const handleOnChange = (isValid: boolean) => {
    setDisableSubmit(!isValid);
    if (!touched) setTouched(true);
  };

  return (
    <Form action={action} onSubmit={onSubmit} className="login-form__container" noValidate>
      <LoginHeaders />
      <Messages loading={loading} messages={messages} />
      <LoginFields fields={fields} onChange={handleOnChange} />
      <LoginButtons loading={loading} disabled={disableSubmit && touched} />
    </Form>
  );
};

export default Login;
