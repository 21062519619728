import { UiText } from 'interfaces/kratos';
import uniqBy from 'lodash/uniqBy';
import { Alert } from '@carecru/component-library';

type MessagesType = {
  messages: UiText[];
  loading: boolean;
};

const Messages = ({ messages, loading }: MessagesType) => (
  <div className="auth-form__messages-container">
    {!loading &&
      uniqBy(messages, 'text').map((message: UiText, index: number) => {
        const variant = message.type === 'error' ? 'danger' : undefined;
        return index === 0 ? (
          <Alert className="auth-form__message" variant={variant} key={message.id}>
            {message.text}
          </Alert>
        ) : null;
      })}
  </div>
);

export default Messages;
