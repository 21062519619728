import React, { ReactNode, ReactNodeArray, ReactElement, JSXElementConstructor } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

// Using translation as string
const Translated = (props: MessageDescriptor & {
  values?: Record<string, ReactNode>;
  tagName?: React.ElementType<any>;
  children?: ((...nodes: ReactNodeArray) => ReactElement<any, string | JSXElementConstructor<any>> | null);
}) => (
  <FormattedMessage {...props} />
);

export default Translated;
