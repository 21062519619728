/* istanbul ignore file */
/// <reference types="@welldone-software/why-did-you-render" />

import React from 'react';
import config from './config';

/* eslint-disable global-require */
if (config.env.isDev && config.env.debug) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackHooks: true,
    trackAllPureComponents: true,
  });
}
