import { Button, Spinner } from '@carecru/component-library';
import { Translated } from 'i18n';

const LoginButtons = ({ loading, disabled }: { loading: boolean; disabled: boolean }) => (
  <div className="login-form__buttons">
    <Button
      as="a"
      variant="secondary"
      href="/auth/recovery"
      className="login-form__forgot-password-btn"
    >
      <Translated id="view_login_forgot" />
    </Button>
    <Button
      className="login-form__login-btn"
      disabled={loading || disabled}
      variant="primary"
      type="submit"
    >
      {loading && (
        <>
          <Spinner
            data-testid="login-spinner"
            className="login-form__login-btn-spinner"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="light"
          />
        </>
      )}
      <Translated id="form_button_login" />
    </Button>
  </div>
);

export default LoginButtons;
