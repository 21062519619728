import axios, { AxiosPromise } from 'axios';
import config from 'config';
import { SettingsPasswordFormDataType, SettingsProfileFormDataType } from 'interfaces/kratos';

export const generateSettingsFlow = () =>
  axios.get(`${config.kratos.baseUrl}/self-service/settings/browser`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const generateSettingsRequest =
  (flowUrl: string, formData: SettingsPasswordFormDataType | SettingsProfileFormDataType) =>
  (): AxiosPromise =>
    axios.post(flowUrl, formData);
