/* eslint-disable @typescript-eslint/indent */
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import BsModal, { ModalProps } from 'react-bootstrap/Modal';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/esm/helpers';
import { ModalDialogProps, ModalHeaderProps } from 'react-bootstrap';

type ModalType = BsPrefixRefForwardingComponent<'div', ModalProps> & {
  Body: BsPrefixRefForwardingComponent<'div', unknown>;
  Header: React.ForwardRefExoticComponent<
    ModalHeaderProps & React.RefAttributes<HTMLDivElement>
  >;
  Title: BsPrefixRefForwardingComponent<
    React.ForwardRefExoticComponent<
      Pick<
        React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLDivElement>,
          HTMLDivElement
        >,
        'key' | keyof React.HTMLAttributes<HTMLDivElement>
      > &
        React.RefAttributes<HTMLDivElement>
    >,
    unknown
  >;
  Footer: BsPrefixRefForwardingComponent<
    React.ForwardRefExoticComponent<
      Pick<
        React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLDivElement>,
          HTMLDivElement
        >,
        'key' | keyof React.HTMLAttributes<HTMLDivElement>
      > &
        React.RefAttributes<HTMLDivElement>
    >,
    unknown
  >;
  Dialog: React.ForwardRefExoticComponent<ModalDialogProps>;
};

const Modal: BsPrefixRefForwardingComponent<'div', ModalProps> = forwardRef(
  ({ dialogClassName, ...props }: ModalProps, ref) => (
    <BsModal
      ref={ref}
      {...props}
      dialogClassName={clsx('mfe-container', dialogClassName)}
    />
  ),
);

export default Object.assign(Modal, {
  Body: BsModal.Body,
  Header: BsModal.Header,
  Title: BsModal.Title,
  Footer: BsModal.Footer,
  Dialog: BsModal.Dialog,
}) as ModalType;
