import {
  isSamePassword,
  validateEmail,
  validateLowercase,
  validateMinCharacter,
  validateNumber,
  validatePassword,
  validatePasswordPattern,
  validatePasswordRequired,
  validateUppercase,
} from 'helpers/validation';
import { KratosFormTypes, UiText } from 'interfaces/kratos';
import { isEmpty } from 'lodash';

export const validateFormIdentifier = (
  formData: Record<string, string> | undefined,
  callback: React.Dispatch<React.SetStateAction<any>>
) => {
  if (!formData) return { email: '', error: false };
  const email = formData.password_identifier || formData.identifier || formData.email;
  const isEmailValidIdentifier = validateEmail(email);
  const error = !isEmailValidIdentifier;
  callback({
    value: email,
    error,
  });
  return { email, error };
};

export const validateFormPassword = (
  formData: Record<string, string> | undefined,
  error: boolean,
  type: KratosFormTypes,
  callback: React.Dispatch<React.SetStateAction<any>>
) => {
  if (!formData) return { password: undefined, error: false };
  const passwordValidated = validatePassword(formData.password, {
    min: 8,
    validateStrong: type === 'settings',
  });

  if (
    !passwordValidated.error &&
    type === 'settings' &&
    !isSamePassword(passwordValidated.value, formData.password2)
  ) {
    passwordValidated.error = true;
    passwordValidated.translationId = 'form_validation_password_match';
  }

  callback(passwordValidated);
  return {
    password: passwordValidated.value,
    error: error || passwordValidated.error,
  };
};

export const validateEmailField = (value: string) => {
  const isEmailEmpty = isEmpty(value);
  const invalidEmail = !validateEmail(value);
  const emptyText = 'Email is required';
  const invalidText = 'Please enter a valid email address';

  let errorMessages = [
    {
      context: {
        reason: emptyText,
      },
      id: 0,
      text: emptyText,
      type: 'error',
    },
  ];

  if (!isEmailEmpty && invalidEmail) {
    errorMessages[0].context.reason = invalidText;
    errorMessages[0].text = invalidText;
  }

  if (!isEmailEmpty && !invalidEmail) {
    errorMessages = [];
  }

  return { isEmailEmpty, invalidEmail, errorMessages };
};

export const validatePasswordField = (
  value: string,
  requiredOnly?: boolean
): {
  errorMessages: UiText[];
  invalidPassword: boolean;
} => {
  const invalidPassword = !validatePasswordPattern(value)?.isValid;
  const validationCase = requiredOnly
    ? [validatePasswordRequired(value)]
    : [
        validatePasswordPattern(value),
        validateLowercase(value),
        validateUppercase(value),
        validateNumber(value),
        validateMinCharacter(value),
      ];
  const messages =
    validationCase.reduce((acc: UiText[] | null, curr: UiText | null) => {
      if (curr) acc!.push(curr);
      return acc;
    }, []) || [];
  const errorMessages = invalidPassword ? messages : [];

  return {
    invalidPassword,
    errorMessages,
  };
};
