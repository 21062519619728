import { Button, Spinner } from '@carecru/component-library';
import { useNavigate } from 'react-router-dom';
import { Translated } from 'i18n';
import { fireLogoutRequest, generateLogoutFlow } from 'views/Login/helpers';
import { useCallback, useEffect, useState } from 'react';
import { LogoutUrl } from 'interfaces/kratos';
import { AxiosResponse, AxiosError } from 'axios';

const SettingsButtons = ({ loading, isSignup }: { loading: boolean; isSignup: boolean }) => {
  const navigate = useNavigate();
  const [logoutUrl, setLogoutUrl] = useState<LogoutUrl>();

  const handleReturnToSignIn = () => {
    if (logoutUrl)
      fireLogoutRequest(logoutUrl).then(() => navigate('/auth/login', { replace: true }));
  };

  const onSuccessfulFlowGeneration = (response: AxiosResponse): void => setLogoutUrl(response.data);

  const navigateToCCP = useCallback((): void => {
    window.location.replace(`${window.location.protocol}//${window.location.host}/`);
  }, []);

  const onFailedFlowGeneration = useCallback(
    (error: AxiosError): void => {
      if (error?.response?.status === 400) {
        navigateToCCP();
      }
    },
    [navigateToCCP]
  );

  useEffect(() => {
    generateLogoutFlow().then(onSuccessfulFlowGeneration).catch(onFailedFlowGeneration);
  }, [onFailedFlowGeneration]);

  return (
    <div className="settings-form__buttons">
      {!isSignup && (
        <Button
          className="settings-form__return-to-sign-in"
          variant="secondary"
          onClick={handleReturnToSignIn}
        >
          <Translated id="form_button_return_to_signin" />
        </Button>
      )}
      <Button
        className="settings-form__send-settings-link-btn"
        disabled={loading}
        variant="primary"
        type="submit"
      >
        {loading && (
          <>
            <Spinner
              className="settings-form__settings-btn-spinner"
              data-testid="settings-spinner"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="light"
            />
          </>
        )}
        <Translated id={isSignup ? 'view_signup_title' : 'view_settings_reset'} />
      </Button>
    </div>
  );
};

export default SettingsButtons;
