import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import TypographyProps from './Typography.types';

const FONT_SIZE_CLASSES = {
  display1: 'display-1',
  display2: 'display-2',
  display3: 'display-3',
  display4: 'display-4',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  lg: 'font-size-lg',
  sm: 'font-size-sm',
};

const Typography: FunctionComponent<TypographyProps> = ({
  as = 'span',
  children,
  className,
  color,
  size,
  weight,
  ...rest
}: TypographyProps) => {
  const Element = as;
  const colorClass = color ? `text-${color}` : null;
  const fontSizeClass = size ? FONT_SIZE_CLASSES[size] : null;
  const fontWeightClass = weight ? `font-weight-${weight}` : null;
  return (
    <Element data-testid="cru-typography" className={clsx([className, colorClass, fontSizeClass, fontWeightClass])} {...rest}>
      {children}
    </Element>
  );
};

export default Typography;
