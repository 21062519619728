import { Translated } from 'i18n';
import { Typography } from '@carecru/component-library';

const RecoveryHeaders = () => (
  <div className="recovery-form__headers">
    <Typography className="recovery-form__title">
      <Translated id="view_recovery_title" />
    </Typography>
    <Typography size="sm" className="recovery-form__description">
      <Translated id="view_recovery_description" />
    </Typography>
  </div>
);

export default RecoveryHeaders;
