import React from 'react';
import BsButton from 'react-bootstrap/Button';
import { Icon } from '../Icon';
import { IconButtonProps } from './Button.types';

/**
 * Primary UI component for user interaction.
 */
const Button = BsButton;

/**
 * Icon Button component
 */
const IconButton = ({ icon, ...rest }: IconButtonProps) => (
  <Button bsPrefix="cru-icon-button" aria-label="icon-button" aria-labelledby="" data-testid="cru-icon-button" {...rest}>
    <Icon icon={icon} className="cru-icon-button__icon" />
  </Button>
);

export { IconButton };

export default Button;
