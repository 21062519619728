import config from 'config';
import axios, { AxiosPromise } from 'axios';
import { RecoveryFormDataType } from 'interfaces/kratos';

export const generateRecoveryFlow = (flowId?: string | null) => {
  let url = `${config.kratos.baseUrl}/self-service/recovery/browser`;

  if (flowId) {
    url = `${config.kratos.baseUrl}/self-service/recovery?flow=${flowId}`;
  }
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const fireRecoveryRequest = (
  flowUrl: string,
  formData: RecoveryFormDataType
): AxiosPromise => axios.post(flowUrl, formData);
