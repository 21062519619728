import axios from 'axios';
import config from 'config';

export const retrieveKratosSession = async () =>
  axios
    .get(`${config.kratos.baseUrl}/sessions/whoami`)
    .then(({ data }) => Promise.resolve(data))
    .catch((err) => {
      if (err.response?.status === 401) {
        console.error('Session is not authenticated. Redirecting...', err);
      }

      return Promise.reject(err);
    });

export const retrieveLogoutUrl = async () =>
  axios
    .get(`${config.kratos.logoutUrlEndpoint}`)
    .then(({ data }) => Promise.resolve(data))
    .catch((err) => Promise.reject(err));

export default retrieveKratosSession;
