/** @jsxImportSource @welldone-software/why-did-you-render */
/* istanbul ignore file */
import './whyDidYouRender';
import './scss/styles.scss';
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { ModalError, Profiler } from 'components';
import { ErrorBoundary } from 'react-error-boundary';
import { getLocale, getMessages } from 'i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import reportWebVitals from './reportWebVitals';

window.renderAuth = (containerId: string) => {
  const locale = getLocale();
  ReactDOM.render(
    <React.StrictMode>
      <Profiler>
        <ErrorBoundary
          FallbackComponent={({ error, resetErrorBoundary }) => (
            <ModalError error={error} isVisible={!!error} onClose={() => resetErrorBoundary()} />
          )}
        >
          <IntlProvider locale={locale} messages={getMessages(locale)}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </IntlProvider>
        </ErrorBoundary>
      </Profiler>
    </React.StrictMode>,
    document.getElementById(containerId)
  );

  reportWebVitals();
};

window.unmountAuth = (containerId: string) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId) as HTMLElement);
};
