import { UiNode } from '@ory/kratos-client';
import { isEmpty } from 'lodash';
import zxcvbn from 'zxcvbn';

export enum PasswordValidation {
  lowerCase,
  upperCase,
  number,
  minLength,
  required,
}

export const validateEmail = (value: string) => {
  const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegExp.test(value);
};

export const validatePassword = (
  value: string,
  options: { min?: number; validateStrong?: boolean } = { min: 8, validateStrong: false }
) => {
  const result = {
    value,
    error: false,
    msg: '',
    translationId: undefined as undefined | string,
  };

  if (!value.length) {
    result.translationId = 'form_validation_password';
    result.error = true;
    return result;
  }

  if (value.length < options.min! || !value) {
    result.translationId = 'form_validation_password';
    result.error = true;
    return result;
  }

  /* istanbul ignore next */
  if (options.validateStrong) {
    const {
      score,
      feedback: { warning },
    } = zxcvbn(value);
    if (score <= 1) {
      result.error = true;
      result.translationId = 'form_validation_password_strong';
    } else if (warning) {
      result.error = true;
      result.msg = warning;
    }
  }

  return result;
};

export const isSamePassword = (password: string, confirmPassword: string) =>
  password && confirmPassword && password === confirmPassword;

export const validateLowercase = (value: string) => {
  const valid = /([a-z])/.test(value);
  return !valid
    ? {
        context: {
          reason: '',
        },
        id: PasswordValidation.lowerCase,
        text: '',
        type: 'error',
        isValid: valid,
      }
    : null;
};

export const validateUppercase = (value: string) => {
  const valid = /([A-Z])/.test(value);
  return !valid
    ? {
        context: {
          reason: '',
        },
        id: PasswordValidation.upperCase,
        text: '',
        type: 'error',
        isValid: valid,
      }
    : null;
};

export const validateNumber = (value: string) => {
  const valid = /([\d])/.test(value);
  return !valid
    ? {
        context: {
          reason: '',
        },
        id: PasswordValidation.number,
        text: '',
        type: 'error',
        isValid: valid,
      }
    : null;
};

export const validateMinCharacter = (value: string) => {
  const valid = /.{8,}/.test(value);
  return !valid
    ? {
        context: {
          reason: '',
        },
        id: PasswordValidation.minLength,
        text: '',
        type: 'error',
        isValid: valid,
      }
    : null;
};

export const validatePasswordPattern = (value: string) => {
  const valid =
    /^(?:(?:(?=.*[\d])(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))|(?:(?=.*[\d])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))|(?:(?=.*[\d])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))).{8,}$/.test(
      value
    );
  return !valid
    ? {
        context: {
          reason: 'Password does not meet the requirements',
        },
        id: PasswordValidation.minLength,
        text: 'Password does not meet the requirements',
        type: 'error',
        isValid: valid,
      }
    : null;
};

export const validatePasswordRequired = (value: string) => {
  const valid = !isEmpty(value);
  return !valid
    ? {
        context: {
          reason: 'Password is required',
        },
        id: PasswordValidation.required,
        text: 'Password is required',
        type: 'error',
        isValid: valid,
      }
    : null;
};

export const validateForm = (fields: UiNode[]): boolean =>
  fields?.every((field: UiNode) => isEmpty(field.messages));
