import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import { extractAction, extractFields, extractMessages } from 'helpers/flow';
import { RecoveryFlow, RecoveryFormDataType } from 'interfaces/kratos';
import serialize from 'form-serialize';
import { Form } from '@carecru/component-library';
import Messages from 'components/Messages';
import { useURLSearchParams } from 'hooks';
import { AxiosError, AxiosResponse } from 'axios';
import { fireRecoveryRequest, generateRecoveryFlow } from './helpers';
import { RecoveryHeaders, RecoveryFields, RecoveryButtons } from './components';

const Recovery: React.FC = () => {
  const navigate = useNavigate();
  const [flow, setFlow] = useState<RecoveryFlow>();
  const [loading, setLoading] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [touched, setTouched] = useState<boolean>(false);

  const fields = extractFields(flow);
  const action = extractAction(flow);
  const messages = extractMessages(flow);
  const query = useURLSearchParams();

  const onSuccessfulRecoveryFlowGeneration = (response: AxiosResponse): void => {
    setFlow(response.data);
    setLoading(false);
  };

  const onFailedRecoveryFlowGeneration = (error: AxiosError): void => {
    setFlow(error?.response?.data);
    setLoading(false);
  };

  const requestRecoveryFlow = (): void => {
    setLoading(true);
    const existingFlowId = query.get('flow');
    generateRecoveryFlow(existingFlowId)
      .then(onSuccessfulRecoveryFlowGeneration)
      .catch(onFailedRecoveryFlowGeneration);
  };

  const onSuccessfulRecoveryRequest = (formParams: {
    formData: RecoveryFormDataType;
    action: string;
  }): void => {
    setLoading(false);
    navigate('/auth/recovery/success', { state: formParams });
  };

  const onFailedRecoveryRequest = (error: AxiosError): void => {
    setLoading(false);

    const responseFlow = get(error, ['response', 'data']);

    if (responseFlow) {
      setFlow(flow);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!touched || disableSubmit) return;

    setLoading(true);

    const form = e.currentTarget;
    const formData = serialize(form, { hash: true });

    const typedFormData = formData as unknown as RecoveryFormDataType;

    fireRecoveryRequest(form.action, typedFormData)
      .then(() => onSuccessfulRecoveryRequest({ formData: typedFormData, action: form.action }))
      .catch(onFailedRecoveryRequest);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(requestRecoveryFlow, []);

  const handleOnChange = (isValid: boolean) => {
    setDisableSubmit(!isValid);
    if (!touched) setTouched(true);
  };

  return (
    <Form action={action} onSubmit={onSubmit} className="recovery-form__container" noValidate>
      <RecoveryHeaders />
      <Messages messages={messages} loading={loading} />
      <RecoveryFields fields={fields} onChange={handleOnChange} />
      <RecoveryButtons loading={loading} disabled={disableSubmit && touched} />
    </Form>
  );
};

export default Recovery;
