import { Button, Spinner } from '@carecru/component-library';
import { Translated } from 'i18n';

const RecoveryButtons = ({ loading, disabled }: { loading: boolean; disabled: boolean }) => (
  <div className="recovery-form__buttons">
    <Button
      className="recovery-form__return-to-sign-in"
      variant="secondary"
      as="a"
      href="/auth/login"
    >
      <Translated id="form_button_return_to_signin" />
    </Button>
    <Button
      className="recovery-form__send-recovery-link-btn"
      disabled={loading || disabled}
      variant="primary"
      type="submit"
    >
      {loading && (
        <>
          <Spinner
            className="recovery-form__recovery-btn-spinner"
            data-testid="recovery-spinner"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="light"
          />
        </>
      )}
      <Translated id="view_recovery_send_link" />
    </Button>
  </div>
);

export default RecoveryButtons;
