import { IconButton, Typography, Button } from '@carecru/component-library';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Translated } from 'i18n';

const UpdateSettingsConfirmation = () => (
  <div className="updated-password__container">
    <Typography size="h3" className="updated-password__header">
      <IconButton icon={faCheck} size="lg" className="updated-password__check" variant="success" />
      <div className="updated-password__title">
        <Translated id="view_settings_confirmation_title" />
      </div>
    </Typography>
    <Typography className="updated-password__description">
      <Translated id="view_settings_confirmation_description" />
    </Typography>
    <Button
      as="a"
      href="/auth/login"
      variant="primary"
      className="updated-password__return-to-sign"
    >
      <Translated id="form_button_return_to_signin" />
    </Button>
  </div>
);

export default UpdateSettingsConfirmation;
