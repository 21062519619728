import { MessageFormatElement } from 'react-intl';

const Messages = {
  app_name: 'Authentication App',
  title_link_login: 'Login Screen',
  title_link_recovery: 'Recovery Screen',
  title_link_settings: 'Settings Screen',
  title_link_verification: 'Verification Screen',

  form_label_email: 'Email Address',
  form_label_identifier: 'Email Address',
  form_label_password_identifier: 'Email Address',
  form_label_first_name: 'First Name',
  form_label_last_name: 'Last Name',
  form_label_password: 'Password',
  form_label_password2: 'Re-Enter Password', // leave this for now as its needed on legacy app.
  form_label_confirm_password: 'Re-Enter Password',
  form_validation_email: 'Invaild Email Address',
  form_validation_password_identifier: 'Invaild Email Address',
  form_validation_password: 'Invaild Password',
  form_validation_password_min: 'The password should have at least 8 characters',
  form_validation_password_strong: 'Password not strong enough',
  form_validation_password_match: 'Passwords do not match',
  form_button_return_to_signin: 'Return to Sign in',
  form_button_logout: 'Logout',
  form_button_login: 'Sign In',

  form_alert_title: 'Error processing the request',

  view_home: 'Home',
  view_notfound: 'Page 404: Not found',
  view_notfound_description: 'Try again',

  view_user_profile: 'User Profile',

  view_login_title: 'Sign In',
  view_login_forgot: 'Forgot Password',

  view_recovery_title: 'Forgot Password',
  view_recovery_description: "We'll send a recovery link to your email.",
  view_recovery_send_link: 'Send Recovery Link',
  view_recovery_resend: 'Resend Email',
  view_recovery_confirmation_title: 'Recovery Email Has Been Sent',
  view_recovery_confirmation_description:
    "We've sent an email to <b>{email}</b> with the recovery link. Please, make sure to check your spam folder as well. We sent the email from <b>noreply@carecru.com</b>.",
  view_recovery_resend_email_description: 'Resending email to <b>{email}</b>',
  view_settings_title: 'Reset Password',
  view_signup_title: 'Sign Up',
  view_settings_description:
    'Enter a new password for the CareCru account associated with <b>{email}</b>.',
  view_settings_reset: 'Reset Password',
  view_settings_confirmation_title: 'Password Changed',
  view_settings_confirmation_description: 'Your password has been changed successfully.',

  view_error_title: 'Well, this is awkward. An error found! :(',
};

type ID = keyof typeof Messages;

export default Messages as Record<ID, string | MessageFormatElement[]>;
