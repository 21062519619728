/* istanbul ignore file */
/* eslint-disable */ // temporary
import dotenv from 'dotenv';

dotenv.config();

const {
  NODE_ENV = 'development',
  REACT_APP_DEBUG,
  REACT_APP_CONTENT_HOST,
  REACT_APP_KRATOS_URL,
  REACT_APP_MAX_RETRIES,
} = process.env;

const microFrontEnd = {
  contentHost: REACT_APP_CONTENT_HOST || 'auth',
};

const isDev = NODE_ENV === 'development';
const kratosUrl = REACT_APP_KRATOS_URL || 'http://localhost:4433';
const maxRetries = parseInt(REACT_APP_MAX_RETRIES || '5', 10);

const config = {
  microFrontEnd,
  maxRetries,
  env: {
    isDev,
    debug: REACT_APP_DEBUG === 'true',
  },
  kratos: {
    baseUrl: kratosUrl,
    initializeUrl: (type: string) =>
      `${kratosUrl}/self-service/${type}/browser${type === 'login' ? '?refresh=true' : ''}`,
    flowUrl: (type: string, id: string) => `${kratosUrl}/self-service/${type}/flows?id=${id}`,
    logoutUrlEndpoint: `${kratosUrl}/self-service/logout/browser`,
  },
};

export default config;
