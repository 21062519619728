import { ReactNode, forwardRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { UiNode, UiNodeInputAttributes, UiText } from '@ory/kratos-client';
import { Form, Icon, Typography } from '@carecru/component-library';
import { extractFieldErrors } from 'helpers/flow';
import { faCheckCircle, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { PasswordValidation } from 'helpers';

interface FieldType {
  field: UiNode;
  label: ReactNode;
  placeholder: string;
  isInvalid?: boolean;
  className?: string;
  withPopover?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const passwordValidationTypes = [
  { id: PasswordValidation.lowerCase, message: '1 lowercase character' },
  { id: PasswordValidation.upperCase, message: '1 uppercase character' },
  { id: PasswordValidation.number, message: '1 number' },
  { id: PasswordValidation.minLength, message: '8 characters minimum' },
];

const Field: React.ForwardRefRenderFunction<HTMLInputElement, FieldType> = (
  { field, label, placeholder, isInvalid, className, withPopover, onChange }: FieldType,
  ref
) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { Group, Control, Label } = Form;
  if (isEmpty(field)) {
    return null;
  }
  const { attributes } = field;
  const { name, type: inputType, value } = attributes as UiNodeInputAttributes;

  const errorMessages = extractFieldErrors(field);

  const invalid = isInvalid || errorMessages.length > 0;

  const controlType = inputType === 'submit' ? 'hidden' : inputType;

  return (
    <Group controlId={name} className={clsx(className, 'field__group')}>
      {controlType !== 'hidden' && <Label>{label}</Label>}

      <Control
        isInvalid={invalid}
        type={
          controlType &&
          ((name === 'password' || name === 'confirmPassword') && showPassword
            ? 'text'
            : controlType)
        }
        placeholder={placeholder}
        defaultValue={value}
        name={name}
        required
        onChange={onChange}
        ref={ref}
        autoComplete="off"
      />
      {(name === 'password' || name === 'confirmPassword') &&
        (showPassword ? (
          <Icon icon={faEye} onClick={handleShowPassword} className="updated-icon" />
        ) : (
          <Icon icon={faEyeSlash} onClick={handleShowPassword} className="updated-icon" />
        ))}

      {invalid &&
        errorMessages.map((message: UiText) => (
          <Typography color="danger" className="field__error-msg">
            {message.text}
          </Typography>
        ))}

      {withPopover && invalid && inputType === 'password' && showPassword && (
        <div className="field__error-msg__password-validation">
          {passwordValidationTypes.map((type: { id: PasswordValidation; message: string }) => (
            <span
              className={clsx({
                valid: errorMessages.map((message) => message.id).indexOf(type.id) === -1,
              })}
            >
              <Icon icon={faCheckCircle} /> {type.message}
            </span>
          ))}
        </div>
      )}
    </Group>
  );
};

export default forwardRef(Field);
