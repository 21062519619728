import { UiNode } from '@ory/kratos-client';
import { useFormatMessage } from 'i18n';
import Field from 'components/Field';
import isEmpty from 'lodash/isEmpty';
import { useRef, useState } from 'react';
import { validatePasswordField } from 'helpers';
import { extractFieldByName, getTraitsUiNode } from 'helpers/flow';

interface InitialStateType {
  password: string;
  confirmPassword: string;
  invalidPassword: boolean;
  invalidPasswordMatch: boolean;
}

const initialState: InitialStateType = {
  password: '',
  confirmPassword: '',
  invalidPassword: false,
  invalidPasswordMatch: false,
};

const initialConfirmPasswordUiNode = {
  attributes: {
    type: 'password',
    name: 'confirmPassword',
    required: true,
    disabled: false,
    node_type: 'input',
  },
  group: '',
  messages: [],
  meta: {},
  type: 'input',
} as UiNode;

const SettingsFields = ({ fields }: { fields: UiNode[] }) => {
  const [state, setState] = useState(initialState);
  const [confirmPasswordUiNode, setConfirmPasswordUiNode] = useState(initialConfirmPasswordUiNode);
  const confirmPasswordRef: React.RefObject<HTMLInputElement> = useRef(null);
  const formLabelFirstName = useFormatMessage({ id: 'form_label_first_name' });
  const formLabelLastName = useFormatMessage({ id: 'form_label_last_name' });
  const formLabelPassword = useFormatMessage({ id: 'form_label_password' });
  const formLabelConfirmPassword = useFormatMessage({ id: 'form_label_confirm_password' });
  const passwordUiNode = extractFieldByName(fields, 'password');

  const validateConfirmPassword = (name: string, value: string): void => {
    const invalidPasswordMatch =
      name === 'password'
        ? !isEmpty(state.confirmPassword) && state.confirmPassword !== value
        : state.password !== value;
    const messages = [
      {
        context: {
          reason: 'Passwords do not match',
        },
        id: 0,
        text: 'Passwords do not match',
        type: 'error',
      },
    ];
    const newConfirmPasswordMessageText = invalidPasswordMatch ? messages[0].text : '';
    const newConfirmPasswordUiNode = invalidPasswordMatch
      ? {
          ...confirmPasswordUiNode,
          messages,
        }
      : initialConfirmPasswordUiNode;

    if (confirmPasswordRef.current)
      confirmPasswordRef.current.setCustomValidity(newConfirmPasswordMessageText);

    setConfirmPasswordUiNode(newConfirmPasswordUiNode);
    setState({
      ...state,
      invalidPasswordMatch,
      [name]: value,
    });
  };

  const validatePassword = (name: string, value: string): void => {
    const { errorMessages, invalidPassword } = validatePasswordField(value);
    passwordUiNode.messages = errorMessages;

    setState({
      ...state,
      invalidPassword,
      [name]: value,
    });
  };

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    if (name === 'password') validatePassword(name, value);
    validateConfirmPassword(name, value);
  };

  return (
    <div className="settings-form__fields">
      {getTraitsUiNode(fields, 'firstName') && (
        <Field
          field={getTraitsUiNode(fields, 'firstName')}
          label={formLabelFirstName}
          placeholder={formLabelFirstName}
          className="mb-4"
        />
      )}
      {getTraitsUiNode(fields, 'lastName') && (
        <Field
          field={getTraitsUiNode(fields, 'lastName')}
          label={formLabelLastName}
          className="mb-4"
          placeholder={formLabelLastName}
        />
      )}
      <Field
        className="settings-form__password mb-4"
        field={passwordUiNode}
        label={formLabelPassword}
        placeholder={formLabelPassword}
        withPopover
        isInvalid={state.invalidPassword}
        onChange={handleChange}
      />
      <Field
        className="settings-form__confirm-password mb-4"
        field={confirmPasswordUiNode}
        label={formLabelConfirmPassword}
        placeholder={formLabelConfirmPassword}
        isInvalid={state.invalidPasswordMatch}
        onChange={handleChange}
        ref={confirmPasswordRef}
      />
    </div>
  );
};

export default SettingsFields;
