import en from './en';
import fr from './fr';
import es from './es';

export type SupportedTranslations = 'en' | 'fr' | 'es';
export type MessagesType = typeof en;

const Messages: Record<SupportedTranslations, MessagesType> = {
  en,
  fr,
  es,
};

export default Messages;
