import get from 'lodash/get';
import { SettingsFlow } from 'interfaces/kratos';
import { Translated } from 'i18n';
import { Typography } from '@carecru/component-library';

const SettingsHeaders = ({ flow, isSignup }: { flow: SettingsFlow; isSignup: boolean }) => {
  const email = get(flow, ['identity', 'traits', 'emails', '0'], '');

  return (
    <div className="settings-form__headers">
      <Typography size="h5" className="settings-form__title">
        <Translated id={isSignup ? 'view_signup_title' : 'view_settings_title'} />
      </Typography>
      <Typography size="sm" className="settings-form__description">
        <Translated
          id="view_settings_description"
          values={{
            b: (chunks: string) => <b>{chunks}</b>,
            email,
          }}
        />
      </Typography>
    </div>
  );
};

export default SettingsHeaders;
