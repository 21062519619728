import { UiNode, UiNodeInputAttributes } from '@ory/kratos-client';
import { useFormatMessage } from 'i18n';
import Field from 'components/Field';
import { validateEmailField, validateForm, validatePasswordField } from 'helpers';
import { useState } from 'react';
import { extractFieldByName } from 'helpers/flow';

type TranslationMapType = {
  password_identifier: string;
  identifier: string;
  password: string;
  method: string;
};

const useTranslationMap = (): TranslationMapType => ({
  password_identifier: useFormatMessage({ id: 'form_label_password_identifier' }),
  identifier: useFormatMessage({ id: 'form_label_identifier' }),
  password: useFormatMessage({ id: 'form_label_password' }),
  method: useFormatMessage({ id: 'view_login_title' }),
});

interface InitialStateType {
  email: string;
  invalidEmail: boolean;
  password: string;
  invalidPassword: boolean;
}

const initialState: InitialStateType = {
  email: '',
  invalidEmail: false,
  password: '',
  invalidPassword: false,
};

const LoginFields = ({
  fields,
  onChange,
}: {
  fields: UiNode[];
  onChange: (isValid: boolean) => void;
}) => {
  const translationMap = useTranslationMap();
  const [state, setState] = useState(initialState);
  const emailUiNode =
    extractFieldByName(fields, 'identifier') || extractFieldByName(fields, 'password_identifier');
  const passwordUiNode = extractFieldByName(fields, 'password');

  const validateEmail = (value: string): void => {
    const { errorMessages, invalidEmail } = validateEmailField(value);
    emailUiNode.messages = errorMessages;

    setState({
      ...state,
      invalidEmail,
    });
  };

  const validatePassword = (value: string): void => {
    const { errorMessages, invalidPassword } = validatePasswordField(value, true);
    passwordUiNode.messages = errorMessages;

    setState({
      ...state,
      invalidPassword,
    });
  };

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    if (name === 'password_identifier' || name === 'identifier') validateEmail(value);
    if (name === 'password') validatePassword(value);

    const isValid = validateForm(fields);
    onChange(!!isValid);
  };

  return (
    <div className="login-form__fields">
      {fields.map((field) => {
        const attributes = field.attributes as UiNodeInputAttributes;
        const { name } = attributes;
        return (
          <Field
            key={name}
            className={`login-form__${name} mb-4`}
            field={field}
            label={translationMap[name as keyof TranslationMapType]}
            placeholder={translationMap[name as keyof TranslationMapType]}
            onChange={handleChange}
          />
        );
      })}
    </div>
  );
};

export default LoginFields;
