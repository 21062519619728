import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { retrieveKratosSession, retrieveLogoutUrl } from 'helpers';
import UserProfile from './UserProfile';

const Home: React.FC = () => {
  const [session, setSession] = useState<any>();
  const navigate = useNavigate();

  const [logoutUrl, setLogoutUrl] = useState<string>('');

  const retrieveSession = async () => {
    try {
    const sessionData = await retrieveKratosSession();
    setSession(sessionData);
    } catch(err) {
      navigate('/auth/login');
    }
  };

  const retrieveLogoutUrlData = async () => {
    const logoutUrlData = await retrieveLogoutUrl();
    setLogoutUrl(logoutUrlData.logout_url);
  };
  
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!session) {
      retrieveSession();
      retrieveLogoutUrlData();
    }
  }, []);

  return (
    <div data-testid="home" className="home-container">
      <UserProfile session={session} logoutUrl={logoutUrl} />
    </div>
  );
};

export default Home;
