import axios, { AxiosPromise } from 'axios';
import { LoginFormDataType, LogoutUrl } from 'interfaces/kratos';
import config from 'config';

export const generateLoginFlow = (): AxiosPromise =>
  axios.get(`${config.kratos.baseUrl}/self-service/login/browser`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const fireLoginRequest = (flowUrl: string, formData: LoginFormDataType): AxiosPromise =>
  axios.post(flowUrl, formData);

export const generateLogoutFlow = (): AxiosPromise =>
  axios.get(`${config.kratos.baseUrl}/self-service/logout/browser`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const fireLogoutRequest = (logout: LogoutUrl): AxiosPromise => axios.get(logout.logout_url);
