/* istanbul ignore file */
import Layout from 'layouts/MainLayout';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  Home,
  Settings,
  Login,
  Recovery,
  UpdateSettingsConfirmation,
  ForgetPasswordConfirmation,
} from 'views';

const renderFn = (component: any) => () => {
  const Component = component;
  return (
    <Layout>
      <Component />
    </Layout>
  );
};

const RoutesComponent: React.FC = () => (
  <Routes>
    <Route path="/auth/login" element={renderFn(Login)()} />
    <Route path="/auth/profile" element={renderFn(Home)()} />
    <Route path="/auth/recovery" element={renderFn(Recovery)()} />
    <Route path="/auth/recovery/success" element={renderFn(ForgetPasswordConfirmation)()} />
    <Route path="/auth/reset" element={renderFn(Settings)()} />
    <Route path="/auth/reset/success" element={renderFn(UpdateSettingsConfirmation)()} />
    <Route path="/auth" element={<Navigate to="/auth/profile" />} key="defaultPath" />
  </Routes>
);

export default RoutesComponent;
