import { Card } from 'components';
import Logo from './Logo';

export interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC = ({ children }: MainLayoutProps) => (
  <div className="main-layout" data-testid="layout-main">
    <div id="bg" >
      <div className="bg-logo">
      <Logo />
    </div>
    </div>
    <div className="main-layout__content">
      <Card>{children}</Card>
    </div>
  </div>
);

MainLayout.displayName = 'MainLayout';

export default MainLayout;
