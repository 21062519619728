import Messages, { SupportedTranslations } from '../translations';

// Can change this function to get the locale from user settings instead of the browser
// language without region code

/**
 * Gets the language code for the user
 * @returns string
 */

export const getLocale = (): SupportedTranslations => {
  const locale = navigator.language.split(/[-_]/)[0];
  if (!['en', 'es', 'fr'].includes(locale)) return 'en';
  return locale as SupportedTranslations;
};
/**
 * Gets the strings to be used in the Provider
 * @returns string
 */
export const getMessages = (locale = getLocale()) => Messages[locale] as Record<string, string>;
