import { Session } from '@ory/kratos-client';
import { FormattedMessage } from 'react-intl';
import styles from './profile.module.scss';

interface UserProfileProps {
  session?: Session;
  logoutUrl: string;
}

const UserProfile = ({ session, logoutUrl }: UserProfileProps) => (
  <div className="user-profile__container" data-testid="view-user-profile">
    <div>
      <FormattedMessage id="view_user_profile" />
    </div>
    {session && session.identity.traits.avatarUrl && (
      <div className={styles.profile__avatar}>
        <img alt="avatar" src={session.identity.traits.avatarUrl} />
      </div>
    )}
    {session && (
      <div className={styles.profile}>
        <div className={styles.profile__row}>
          <div className={styles.profile__prop}>ID:</div>
          <div className={styles.profile__Value}>{session.identity.id}</div>
        </div>
        <div className={styles.profile__row}>
          <div className={styles.profile__prop}>Email:</div>
          <div className={styles.profile__Value}>
            {(session.identity.traits as any).emails.join(', ')}
          </div>
        </div>
        <div className={styles.profile__row}>
          <div className={styles.profile__prop}>First Name:</div>
          <div className={styles.profile__Value}>{(session.identity.traits as any).firstName}</div>
        </div>
        <div className={styles.profile__row}>
          <div className={styles.profile__prop}>Last Name:</div>
          <div className={styles.profile__Value}>{(session.identity.traits as any).lastName}</div>
        </div>
        <div className={styles.profile__row}>
          <div className={styles.profile__prop}>Role:</div>
          <div className={styles.profile__Value}>{(session.identity.traits as any).role}</div>
        </div>
        <div className={styles.profile__row}>
          <div className={styles.profile__prop}>Is SSO:</div>
          <div className={styles.profile__Value}>
            {(session.identity.traits as any).isSSO.toString()}
          </div>
        </div>
      </div>
    )}
    <div style={{ textAlign: 'right', paddingTop: 32 }}>
      <a className="btn btn-primary" href="/auth/reset" style={{ marginRight: 10 }}>
        Update Password
      </a>
      <a className="btn btn-primary" href={logoutUrl}>
        <FormattedMessage id="form_button_logout" />
      </a>
    </div>
  </div>
);

export default UserProfile;
