import { Modal } from '@carecru/component-library';
import { FormattedMessage } from 'react-intl';

interface ModalErrorProps {
  error?: Error | string;
  isVisible: boolean;
  onClose?: () => void;
}

const ModalError: React.FC<ModalErrorProps> = ({ error, isVisible, onClose }: ModalErrorProps) => (
    <>
      {isVisible && (
        <Modal show={isVisible} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="view_error_title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body data-testid="modal-body">
            <span style={{ textAlign: 'left' }}>
              <p>{typeof error === 'string' ? error : error?.message}</p>
            </span>
            {typeof error !== 'string' && <details>{error?.stack}</details>}
          </Modal.Body>
        </Modal>
      )}
    </>
  );

ModalError.displayName = 'ModalError';

export default ModalError;
