import React from 'react';
import BsSpinner from 'react-bootstrap/Spinner';
import SpinnerProps from './Spinner.types';

/**
 * Spinners can be used to show the loading state in your projects.
 */
const Spinner = ({
  ...props
}: SpinnerProps) => (
  <BsSpinner {...props} />
);

export default Spinner;
