import { useState } from 'react';
import { Typography, Button, Spinner } from '@carecru/component-library';
import { Translated } from 'i18n';
import { RecoveryFormDataType, UiText } from 'interfaces/kratos';
import { Messages } from 'components';
import { setTimeout } from 'timers';
import { fireRecoveryRequest } from '../helpers';

const ForgetPasswordConfirmation = ({
  formData,
  formAction,
}: {
  formData?: RecoveryFormDataType;
  formAction: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<UiText[]>([]);

  const onResendEmailClick = () => {
    setLoading(true);
    fireRecoveryRequest(formAction, formData as RecoveryFormDataType)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setMessages([
          {
            id: 0,
            text: error.toString(),
            type: 'error',
          },
        ]);
        setLoading(false);
        setTimeout(() => {
          setMessages([]);
        }, 3000);
      });
  };

  return (
    <div className="forgot-password-success__container">
      <div className="forgot-password-success__message-container">
        <Typography size="h5" className="forgot-password-success__header">
          <span>
            <Translated id="view_recovery_confirmation_title" />
          </span>
        </Typography>
        {!loading && (
          <>
            <Typography className="forgot-password-success__description">
              <Translated
                id="view_recovery_confirmation_description"
                values={{
                  b: (chunks: string) => <b>{chunks}</b>,
                  email: formData?.email,
                }}
              />
            </Typography>
          </>
        )}

        {loading && (
          <>
            <Typography className="forgot-password-success__description">
              <Translated
                id="view_recovery_resend_email_description"
                values={{
                  b: (chunks: string) => <b>{chunks}</b>,
                  email: formData?.email,
                }}
              />
            </Typography>
            <Spinner
              data-testid="forgot-password-success-spinner"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
            />
          </>
        )}
      </div>
      <Messages messages={messages} loading={loading} />
      <div className="buttonContainer">
        <Button
          onClick={onResendEmailClick}
          variant="secondary"
          className="forgot-password-success__resend-email-btn"
          disabled={loading}
        >
          <Translated id="view_recovery_resend" />
        </Button>
        <Button
          as="a"
          href="/auth/login"
          variant="primary"
          className="forgot-password-success__return-to-signin"
        >
          <Translated id="form_button_return_to_signin" />
        </Button>
      </div>
    </div>
  );
};

export default ForgetPasswordConfirmation;
