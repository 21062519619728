
export interface CardProps {
  children?: React.ReactNode;
}

const Container: React.FC = ({ children }: CardProps) => (
  <div className="card-box">
    <div className="card-content">{children}</div>
  </div>
);

export default Container;
